import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

import { DOCUMENT } from '@angular/common';
// declare var $: any;
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.css']
})

export class OrderPaymentComponent implements OnInit {
  @ViewChild('btnPaymentForm', { static: false }) btnPaymentForm: ElementRef;

  language: any
  orderDetails: any
  // wpwlOptions: any

  paymenttype: any
  checkoutId: any
  paymentError = null
  loadingAddPayment: boolean

  wpwlOptions = {
    onReady: function () {
      let createRegistrationHtml = '<div class="customLabel">Store payment details?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
      $('form.wpwl-form-card').find('.wpwl-button').before(createRegistrationHtml);
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document) {

    this.language = this.activeRoute.snapshot.params['lang'];
    console.log('this.language --->', this.language);

    if (!this.language)
      this.authService.setLanguage('ar');

    if (this.language == 'ar') {
      this.translate.use('ar');
      this.document.getElementById('rtlBootstrapStyles').setAttribute('href', 'assets/css/style_ar.css');
    }

    else if (this.language == 'en') {
      this.translate.use('en');
      this.document.getElementById('layoutStyles').setAttribute('href', 'assets/css/style_en.css');
      this.document.getElementById('rtlBootstrapStyles').removeAttribute('href');
    }

  }

  ngOnInit() {
    this.activeRoute.data.subscribe(
      (res) => {
        this.orderDetails = res['type']
        console.log('res --->', this.orderDetails);
      });

    // this.initPayment()
  }

  // initPayment() {
  //   console.log('on ready ----->')
  //   this.wpwlOptions = {
  //     style: "card",
  //     locale: this.language,
  //     paymentTarget: "_top",
  //     onReady: () => {

  //       console.log('on ready ----->');

  //       let createRegistrationHtml = '<div class="customLabel">Store payment details?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
  //       $('form.wpwl-form-card').find('.wpwl-button').before(createRegistrationHtml);

  //       if (this.language == "ar") {
  //         $(".wpwl-group").css('direction', 'ltr');
  //         $(".wpwl-control-cardNumber").css({ 'direction': 'ltr', "text-align": "right" });
  //         $(".wpwl-brand-card").css('right', '200px');
  //       } else {
  //         $(".wpwl-group").css('direction', 'rtl');
  //         $(".wpwl-control-cardNumber").css({ 'direction': 'rtl', "text-align": "left" });
  //         $(".wpwl-brand-card").css('left', '200px');
  //       }
  //     }
  //   };
  // }

  payment(cardType: any) {
    this.loadingAddPayment = true;
    this.authService.payment(this.orderDetails.id, { cardType, registrationIds: [] })
      .subscribe(res => {
        this.loadingAddPayment = false;
        // // these regex based on this https://hyperpay.docs.oppwa.com/reference/resultCodes
        if (res && res['result']
          && (/^(000\.000\.|000\.100\.1|000\.[36])/.test(res['result'].code)
            || /^(000\.400\.0[^3]|000\.400\.100)/.test(res['result'].code)
            || /^(000\.200)/.test(res['result'].code)
            || /^(800\.400\.5|100\.400\.500)/.test(res['result'].code)
          )
        ) {
          //success 
          this.checkoutId = res['id']
          this.paymenttype = cardType
          this.document.getElementById('paymentLink')
            .setAttribute('src', 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=' + this.checkoutId);
          // this.closeBtn.nativeElement.click();
          this.btnPaymentForm.nativeElement.click();
        }
        else {
          console.log('res.description', res['result'].description);

          this.paymentError = res['result'].description
          setTimeout(() => {
            this.paymentError = null;
          }, 3000);
          return;
        }
      }, err => {
        this.loadingAddPayment = false;
      })
  }
}
