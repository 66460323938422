import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountriesService } from '../service/countries.service';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { RestaurantsService } from '../service/resturant.service';

@Component({
  selector: 'app-watting-acceptance',
  templateUrl: './watting-acceptance.component.html',
  styleUrls: ['./watting-acceptance.component.css']
})
export class WattingAcceptanceComponent implements OnInit {
  
  constructor(private authService: AuthService,
    private route: Router) {

  }

  ngOnInit() {
   
  }

  goToLogin(){
    this.authService.logout()
  }

}
