export class Page<T> {
    page: number ;
    pageCount: number;
    limit: number ;
    totalCount: number ;
    data: Array<T>;

    constructor() {
        this.page = 0;
        this.limit = 0 ;
        this.pageCount = 0 ;
        this.totalCount = 0 ;
        this.data = [] ;
    }
}
