import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from '../service/auth.service';
// declare var $;

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
    currentUser: any
    currentLanguage: string;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        // this.rightSide();

        this.getLanguage();
        // $("nav").niceScroll();  
        this.currentUser = this.authService.currentUser.user;

        if (this.currentUser.type == "EMPLOYEE")
            this.currentUser = this.authService.currentUser.user.restaurant


        // $("#ecapsSideNav").niceScroll();
        // $(".ecaps-sidenav").niceScroll();


        this.authService.profileSubject.subscribe(
            (data) => {
                if (data)
                    this.currentUser = this.authService.currentUser.user;
            }
        )
        // "node_modules/jquery.nicescroll/dist/jquery.nicescroll.min.js"

        $("#menuCollasped").on("click", function () {
            $(".ecaps-page-wrapper").toggleClass("menu-collasped-active")
        }),
            $("#mobileMenuOpen").on("click", function () {
                $(".ecaps-page-wrapper").toggleClass("mobile-menu-active")
            }),
            $("#rightSideTrigger").on("click", function () {
                $(".right-side-content").toggleClass("active")
            })


        // Side Menu
        $(document).ready(function () {
            'use strict';
            $('.sidebar-menu .treeview > a').click(function (e) {
                $('.treeview-menu').slideUp(),
                    // tslint:disable-next-line:no-unused-expression
                    $(this)
                        .next()
                        .is(':visible') ||
                    $(this)
                        .next()
                        .slideDown(),
                    e.stopPropagation();
            });
        });
    }



    getLanguage() {
        this.currentLanguage = this.authService.getLanguage();
    }

}
