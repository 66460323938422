import { Observable } from "rxjs";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { OrdersService } from '../service/orders.service';

@Injectable()
export class OrderPaymentResolver implements Resolve<any>{
    constructor(
        private ordersService: OrdersService,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> {
        return this.ordersService.findOrderById(route.params['orderId']).toPromise().then(
            order => order
        ).catch(
            error => {
                console.log(error, 'the error');

                this.router.navigate(['/404']);
                return null;
            }
        );
    }
}
