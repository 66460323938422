import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, RouterLink } from "@angular/router";
import { Observable } from 'rxjs';
import { EmployeeService } from '../service/empoyees.service';
import { AuthService } from '../service/auth.service';


@Injectable()
export class PermissionResolver implements Resolve<any>{
    constructor(
        private authService: AuthService,
        private router: Router,
        private employeeService: EmployeeService) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> |
        Promise<any> | any {
        let resturantId = this.authService.getUserId();
        let employeeId = this.authService.getEmployeeId();

        let permission = Object.values(route.data);
        
        if(employeeId ==0 || !employeeId ) return;
        
        return this.employeeService.checkPermission(permission[0], resturantId, employeeId)
            .toPromise().then(res => {
                console.log('the res ===>',res);
                
            }).catch(error => {
                if (error.status === 403) {
                    this.router.navigate(['no-permissions']);
                }
                return null;

            });

    }
}