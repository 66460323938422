import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: Boolean;
  loginError: Boolean;
  currentLanguage: string;


  constructor(private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document) {
    this.getLanguage();

    console.log('this.currentLanguage ===', this.currentLanguage);

    if (!this.currentLanguage)
      this.authService.setLanguage('ar');

    console.log('this.currentLanguage ===', this.currentLanguage);

    if (this.currentLanguage == 'ar') {
      this.translate.use('ar');
      this.document.getElementById('rtlBootstrapStyles').setAttribute('href', 'assets/css/style_ar.css');

    }
    else if (this.currentLanguage == 'en') {
      this.translate.use('en');

      this.document.getElementById('layoutStyles').setAttribute('href', 'assets/css/style_en.css');
      this.document.getElementById('rtlBootstrapStyles').removeAttribute('href');
    }

  }

  ngOnInit() {

    if (!this.currentLanguage)
      this.authService.setLanguage('ar');

    this.loginForm = new FormGroup({
      phone: new FormControl(null, [Validators.required]),
      phoneKey: new FormControl("+966", []),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });

  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
    if (!this.currentLanguage) this.changeLanguage()
  }

  register() {
    this.router.navigate(['register'])
  }

  changeLanguage() {
    this.authService.setLanguage(this.currentLanguage == 'ar' ? 'en' : 'ar');
    location.reload();
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      Object.keys(this.loginForm.controls).forEach(key => {
        this.loginForm.get(key).markAsTouched();
      });
      return;
    }
    const body = {
      phone: this.loginForm.get('phone').value,
      password: this.loginForm.get('password').value,
    };

    this.loading = true;

    this.authService.login(body).subscribe(res => {

      this.loading = false;
      console.log('the user is ===>', res);

      if (res['user'].type == 'ADMIN') {
        this.loginError = true;

        setTimeout(() => {
          this.loginError = false;
        }, 2000);
        return
      }


      this.authService.setUser(res);
      // const returnUrl = this.activeRoute.snapshot.queryParams.url;

      //  if (returnUrl) {

      //   if (res['user'].firstAcception == false && res['user'].type == 'RESTAURANT')
      //     this.router.navigate(['complete-register']);
      //   else if (res['user'].secondAcception == false && res['user'].type == 'RESTAURANT')
      //     this.router.navigate(['add-resturant-details']);
      //   else if (res['user'].accepted == false && res['user'].type == 'RESTAURANT')
      //     this.router.navigate(['watting-acceptance']);
      //   else {
      //     this.router.navigate([returnUrl]);
      //     console.log(returnUrl,888888);

      //   }
      // }
      // else {
      if (res['user'].verified == false && res['user'].type == 'RESTAURANT')
        this.router.navigate(['verify-account']);

      else if (res['user'].firstAcception == false && res['user'].type == 'RESTAURANT')
        this.router.navigate(['complete-register']);

      else if (res['user'].secondAcception == false && res['user'].type == 'RESTAURANT')
        this.router.navigate(['add-resturant-details']);

      else if (res['user'].accepted == false && res['user'].type == 'RESTAURANT')
        this.router.navigate(['watting-acceptance']);

      else {
        this.router.navigate(['/dashbord']);
      }
      // }

    },
      err => {
        console.log(err);
        this.loading = false;
        if (err.status == 401) {
          this.loginError = true;
        }
        setTimeout(() => {
          this.loginError = false;
        }, 2000);
      }
    );
  }

}
