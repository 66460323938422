import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/service/auth.service';
import { Page } from 'src/app/core/models/page.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})

export class NotificationsComponent implements OnInit {

  currentPage = new Page();
  currentUser: any
  activePage: number;
  loading: boolean;

  currentLanguage: string;

  constructor(private authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser.user;
    this.getLanguage();
    this.getNotifications(1);
  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
  }

  getNotifications(page: number) {
    
    this.loading = true;
    this.authService.getNotifications(this.currentUser.id, page, 12, null).subscribe(notifications => {
      this.loading = false;
      this.currentPage = notifications
      this.activePage = notifications.page;
      console.log('getNotifications --->', this.currentPage);
    })
  }

  showNotificationDetails(notificationsType: any, order: any) {
    if (['BRANCH_ACTIVATION', 'BRANCH_DEACTIVATION','BRANCH_CHANGED_PASSWORD'].includes(notificationsType)) this.router.navigate(['branches',order,'show'])
    if (['PRODUCT_ACTIVATION', 'PRODUCT_DEACTIVATION'].includes(notificationsType)) this.router.navigate(['products', order, 'show'])
    if (['OFFER_ACTIVATION', 'OFFER_DEACTIVATION'].includes(notificationsType)) this.router.navigate(['offers', order, 'show'])
    if (['RATE'].includes(notificationsType)) this.router.navigate(['management'])
  }

  getPage(page: number) {
    if (this.activePage !== page) {
      this.getNotifications(page);
    }
  }

  convertDateToMomentMinutes(date: any) {
    // moment.locale('ar');
    return moment(date, "YYYYMMDD").fromNow()
  }

}
