import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageComponent } from './language/language.component';
import { FooterComponent } from './footer/footer.component';
import { RatingComponent } from './rating/rating.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        LanguageComponent,
        FooterComponent
    ],
    providers: [

    ],
    imports: [
        // BrowserModule,
        CommonModule,
        // StarRatingModule.forRoot(),
        TranslateModule
        // .forRoot({
        // 	loader: {
        // 		provide: TranslateLoader,
        // 		useFactory: HttpLoaderFactory,
        // 		deps: [HttpClient]
        // 	}
        // }),
    ],
    exports: [
        LanguageComponent,
        TranslateModule,
        FooterComponent
    ]
})
export class SharedModule { }
