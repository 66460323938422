import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/core/service/loading.service';

 
@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.css']
})

export class ProgressbarComponent implements OnInit {

  show:boolean;

  constructor(private loadingService: LoadingService) { }
  
  ngOnInit() {
    this.loadingService.loadingSubject.subscribe(result => this.show = result);
  }
}
