import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageInterceptor implements HttpInterceptor {

	constructor() { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	 
		const currentLang = localStorage.getItem('@RES_lang')

		const authReq = req.clone({
			headers: req.headers.set(
				'Accept-Language', `${currentLang}`)
		});

		return next.handle(authReq);
	}
}
