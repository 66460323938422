import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountriesService } from '../service/countries.service';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { RestaurantsService } from '../service/resturant.service';

@Component({
  selector: 'app-first-page-register',
  templateUrl: './first-page-register.component.html',
  styleUrls: ['./first-page-register.component.css']
})

export class FirstPageRegisterComponent implements OnInit {
  AddRestaurantForm: FormGroup;

  isChecked: boolean = false;
  loadCountries: Boolean;
  loadCities: Boolean;
  loadRestaurant: Boolean;
  loginError: Boolean;

  countries: any;
  cities: any;
  phonesError: any = '';
  emailError: any = '';
  isoCode: any;

  constructor(private authService: AuthService,
    private router: Router,
    private restaurantsService: RestaurantsService,
    private countriesService: CountriesService,
    private activeRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.AddRestaurantForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      
      // companyNameAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      // companyNameEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)]),
      // companyAddressAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      // companyAddressEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)]),
      
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      phoneKey: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      country: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      terms: new FormControl(null,[Validators.required]),
      // service: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });

    this.getAllCountries();

    this.AddRestaurantForm.get('country').valueChanges.subscribe(country => {
      let selecedCountry = this.countries.filter((countryId: any) => {
        return countryId.id == country
      })
      if (selecedCountry[0]) {
        this.AddRestaurantForm.get('phoneKey').setValue(selecedCountry[0].key);
        this.isoCode = selecedCountry[0].code
      }

      console.log(selecedCountry[0], 'ssss');

      this.fetchCities(country);
    })
  }

  // existingPhoneValidator(): AsyncValidatorFn {
  //   return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
  //     return this.countriesService.checkNationalNumber({ phone: this.AddRestaurantForm.get('phoneKey').value + control.value, isoCode: this.isoCode })
  //       .pipe(debounceTime(500))
  //       .pipe(map())
  //       // .subscribe((phoneValidAndExist)=>{
  //       //   return (phoneValidAndExist && phoneValidAndExist['duplicated'] == true) ? { "userNameExists": true } : null;
  //       // })
  //   };
  // }

  
  onCheck(event: any) {
    console.log('check box', event);
    this.isChecked = event
    if (event) {
      this.AddRestaurantForm.get('terms').setValidators([]);
      this.AddRestaurantForm.get('terms').updateValueAndValidity()
    } else {
      this.AddRestaurantForm.get('terms').setValidators([Validators.required]);
      this.AddRestaurantForm.get('terms').updateValueAndValidity()
    }
  }

  getAllCountries() {
    this.loadCountries = true;
    this.countriesService.fetchCountries().subscribe(
      countries => {
        this.countries = countries;
        this.loadCountries = false;
      }
    );
  }

  fetchCities(countryId: number) {
    this.loadCities = true;
    this.countriesService.fetchCities(countryId).subscribe(
      cities => {
        this.cities = cities;
        this.loadCities = false;
      }
    );
  }

  onSubmit() {
    if (!this.AddRestaurantForm.valid) {
      Object.keys(this.AddRestaurantForm.controls).forEach(key => {
        this.AddRestaurantForm.get(key).markAsTouched();
      });
      return;
    }

    let resturantBody = {
      'name': this.AddRestaurantForm.get('name').value,
      
      // 'companyNameAr': this.AddRestaurantForm.get('companyNameAr').value,
      // 'companyNameEn': this.AddRestaurantForm.get('companyNameEn').value,
      // 'companyAddressAr': this.AddRestaurantForm.get('companyAddressAr').value,
      // 'companyAddressEn': this.AddRestaurantForm.get('companyAddressEn').value,
      
      'phone': this.AddRestaurantForm.get('phone').value,
      'phoneKey': this.AddRestaurantForm.get('phoneKey').value,
      'email': this.AddRestaurantForm.get('email').value,
      'country': this.AddRestaurantForm.get('country').value,
      'city': this.AddRestaurantForm.get('city').value,
      'service': 'RECEIVEREQUEST',
      // 'service': this.AddRestaurantForm.get('service').value,
      'password': this.AddRestaurantForm.get('password').value
    }
    this.loadRestaurant = true;
    this.restaurantsService.addResturantFirstPage(resturantBody).subscribe(
      resturant => {
        this.loadRestaurant = false;
        this.authService.setUser(resturant);
        this.router.navigate(['verify-account'])
      },
      error => {
        console.log('the error is ====>', error.error.error);

        this.loadRestaurant = false;
        for (let i = 0; i < error.error.errors.length; i++) {

          if (error.error.errors[i].param== 'phone') {
            this.phonesError = error.error.errors[i].msg
            setTimeout(() => {
              this.phonesError = ''
            }, 4000);
          }
          if (error.error.errors[i].param== 'email') {
            this.emailError = error.error.errors[i].msg
            setTimeout(() => {
              this.emailError = ''
            }, 4000);
          }

        }
      }
    );

  }

}
