import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../core/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  currentLanguage: string;


  constructor(private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document) {
    this.getLanguage();


    if (this.currentLanguage == 'ar') {
      this.translate.use('ar');
      this.document.getElementById('rtlBootstrapStyles').setAttribute('href', 'assets/css/style_ar.css');

    }
    else if (this.currentLanguage == 'en') {
      this.translate.use('en');

      this.document.getElementById('layoutStyles').setAttribute('href', 'assets/css/style_en.css');
      this.document.getElementById('rtlBootstrapStyles').removeAttribute('href');
    }

  }

  ngOnInit() {

    if (!this.currentLanguage)
      this.authService.setLanguage('ar');

  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
  }

  changeLanguage() {
    this.authService.setLanguage(this.currentLanguage == 'ar' ? 'en' : 'ar');
    location.reload();
  }

}
