import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountriesService } from '../service/countries.service';
import * as $ from 'jquery';
import { debounceTime } from 'rxjs/operators'
import { RestaurantsService } from '../service/resturant.service';

@Component({
  selector: 'app-second-page-register',
  templateUrl: './second-page-register.component.html',
  styleUrls: ['./second-page-register.component.css']
})

export class SecondPageRegisterComponent implements OnInit {
  CompleteRegistrationRestaurantForm: FormGroup;
  addCategoryForm: FormGroup;

  @ViewChild('commercialRegistrationImage', { static: false }) commercialRegistrationImage: ElementRef = null;
  // @ViewChild('contractImage', { static: false }) contractImage: ElementRef = null;
  @ViewChild('logo', { static: false }) logo: ElementRef = null;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;


  loadCategory: Boolean;
  loadRestaurant: Boolean;
  loadCities: Boolean;
  loadBanks: Boolean;
  loginError: Boolean;

  commercialRegistrationImageError: boolean = false
  contractImageError: boolean = false
  logoError: boolean = false
  newCatgory: boolean = false

  categories: any;
  cities: any;
  banks: any;
  isoCode: any;

  constructor(private authService: AuthService,
    private restaurantsService: RestaurantsService,
    private activeRoute: ActivatedRoute,
    private route: Router) {

    let user = this.authService.currentUser.user

    if (!user.verified)
      this.route.navigate(['verify-account'])
  }


  ngOnInit() {
    this.CompleteRegistrationRestaurantForm = new FormGroup({

      companyNameAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      companyNameEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)]),
      companyAddressAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      companyAddressEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)]),

      brandNameAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      brandNameEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)]),

      commercialRegistrationNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)]),

      taxRecordNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.minLength(15), Validators.maxLength(15)]),

      bankIbanNumber: new FormControl(null, [Validators.required]),
      bank: new FormControl(null, [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      categoryAr: new FormControl(null, []),
      categoryEn: new FormControl(null, []),
      bankAccountNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)])
    });

    $(".file-upload-browse").on("click", function () {
      $(this).parent().parent().parent().find(".file-upload-default").trigger("click")
    }), $(".file-upload-default").on("change", function () {
      $(this).parent().find(".form-control").val($(this).val().replace(/C:\\fakepath\\/i, ""))
    })

    this.addCategoryForm = new FormGroup({
      nameAr: new FormControl(null, [Validators.required, Validators.pattern(/^[\u0621-\u064A0-9, -]+$/)]),
      nameEn: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9$,!%*?&#-^_. +]+$/)])
    });

    this.getAllCategories();
    this.getAllBanks();
  }

  getAllCategories() {
    this.loadCategory = true;
    this.restaurantsService.fetchCategory().subscribe(
      categories => {
        this.categories = categories;
        console.log('this.categories--->', this.categories);
        this.loadCategory = false;
      }
    );
  }

  getAllBanks() {
    this.loadBanks = true;
    this.restaurantsService.fetchBanks().subscribe(
      banks => {
        this.banks = banks;
        console.log('this.banks--->', this.banks);
        this.loadBanks = false;
      }
    );
  }

  onSubmit() {

    if (!this.CompleteRegistrationRestaurantForm.valid) {
      Object.keys(this.CompleteRegistrationRestaurantForm.controls).forEach(key => {
        this.CompleteRegistrationRestaurantForm.get(key).markAsTouched();
      });
      return;
    }

    let commercialRegistrationImage = this.commercialRegistrationImage.nativeElement.files[0];
    if (!commercialRegistrationImage) {
      this.commercialRegistrationImageError = true
      setTimeout(() => {
        this.commercialRegistrationImageError = false;
      }, 4000);
      return;
    }


    // let contractImage = this.contractImage.nativeElement.files[0];
    // if (!contractImage) {
    //   this.contractImageError = true
    //   setTimeout(() => {
    //     this.contractImageError = false;
    //   }, 4000);
    //   return;
    // }

    let logo = this.logo.nativeElement.files[0];
    if (!logo) {
      this.logoError = true
      setTimeout(() => {
        this.logoError = false;
      }, 4000);
      return;
    }


    let resturantBody = new FormData();

    resturantBody.append('companyNameAr', this.CompleteRegistrationRestaurantForm.get('companyNameAr').value);
    resturantBody.append('companyNameEn', this.CompleteRegistrationRestaurantForm.get('companyNameEn').value);
    resturantBody.append('companyAddressAr', this.CompleteRegistrationRestaurantForm.get('companyAddressAr').value);
    resturantBody.append('companyAddressEn', this.CompleteRegistrationRestaurantForm.get('companyAddressEn').value);

    resturantBody.append('brandNameAr', this.CompleteRegistrationRestaurantForm.get('brandNameAr').value);
    resturantBody.append('brandNameEn', this.CompleteRegistrationRestaurantForm.get('brandNameEn').value);
    resturantBody.append('commercialRegistrationNumber', this.CompleteRegistrationRestaurantForm.get('commercialRegistrationNumber').value);
    resturantBody.append('taxRecordNumber', this.CompleteRegistrationRestaurantForm.get('taxRecordNumber').value);
    resturantBody.append('bank', this.CompleteRegistrationRestaurantForm.get('bank').value);
    resturantBody.append('bankIbanNumber', this.CompleteRegistrationRestaurantForm.get('bankIbanNumber').value);
    resturantBody.append('category', this.CompleteRegistrationRestaurantForm.get('category').value);
    resturantBody.append('bankAccountNumber', this.CompleteRegistrationRestaurantForm.get('bankAccountNumber').value);
    // resturantBody.append('contractImage', contractImage);
    resturantBody.append('logo', logo);
    resturantBody.append('commercialRegistrationImage', commercialRegistrationImage);

    this.loadRestaurant = true;

    this.restaurantsService.resturantCompletePage(resturantBody, this.authService.getUserId()).subscribe(
      resturant => {
        this.loadRestaurant = false;
        console.log('register ====>', resturant);

        if (resturant['service'] == 'RECEIVEREQUEST')
          this.route.navigate(['watting-acceptance'])
        else
          this.route.navigate(['add-resturant-details'])

      },
      error => {
        console.log('the error is ====>', error.error.error);
        this.loadRestaurant = false;
      }
    );

  }

  onSubmitCategory() {
    if (!this.addCategoryForm.valid) {
      Object.keys(this.addCategoryForm.controls).forEach(key => {
        this.addCategoryForm.get(key).markAsTouched();
      });
      return;
    }

    let categoryBody = {
      nameAr: this.addCategoryForm.get('nameAr').value,
      nameEn: this.addCategoryForm.get('nameEn').value,
    };

    this.loadCategory = true;
    this.restaurantsService.addCategory(categoryBody)
      .subscribe(
        resturant => {
          this.loadCategory = false;
          console.log('register ====>', resturant);
          this.addCategoryForm.reset();
          this.getAllCategories();
          this.closeModal.nativeElement.click();
        },
        error => {
          console.log('the error is ====>', error);
          this.loadCategory = false;
        }
      );

  }

}
