import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountriesService } from '../service/countries.service';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { RestaurantsService } from '../service/resturant.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})

export class ConfirmAccountComponent implements OnInit {

  verifycode: any;
  loadVerifyCode: any;
  currentUser: any

  verifyCodeError = false;
  verifyCodeLengthError = false;
  verifyCodeNotMatchError: any = '';

  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px',
      'margin': '0px 5px',
      'border': '1px solid #2d3e47;'
    }
  };

  constructor(private authService: AuthService,
    private route: Router) {

  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser
  }

  onOtpChange(otp: any) {

    this.verifycode = otp;

    this.verifyCodeError = !this.verifycode ? true : false
    this.verifyCodeLengthError = this.verifycode.length < 4 ? true : false
  }

  onSubmit() {
    if (!this.verifycode) {
      this.verifyCodeError = true
      return
    }

    if (this.verifycode.length < 4) {
      this.verifyCodeLengthError = true
      return
    }


    this.loadVerifyCode = true
    this.authService.verifyUserAccount({ verifycode: this.verifycode }, this.authService.getUserId())
      .subscribe(res => {
        this.loadVerifyCode = false

        this.authService.setUser({
          user: res,
          token: this.currentUser.token
        });

        this.route.navigate(['complete-register'])
      }, error => {
        this.loadVerifyCode = false
        for (let i = 0; i < error.error.errors.length; i++) {

          if (error.error.errors[i].param == 'verifycode') {
            this.verifyCodeNotMatchError = error.error.errors[i].msg
            setTimeout(() => {
              this.verifyCodeNotMatchError = ''
            }, 4000);
          }

        }
      }
      )

  }

}
