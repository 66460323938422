// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  origin: "https://yahala.app/",
  path: "https://yahala.app/api/v1/",
  
  // DsPath: '34.254.184.64:6021',
  DsPath: 'wss://yahala.app:6021',

  // origin: "http://localhost:3000/",
  // path: "http://localhost:3000/api/v1/",

  // origin: "http://ec2-18-188-169-208.us-east-2.compute.amazonaws.com:3000/",
  // path: "http://ec2-18-188-169-208.us-east-2.compute.amazonaws.com:3000/api/v1/",

  // DsPath: 'ec2-18-188-169-208.us-east-2.compute.amazonaws.com:6021',

  DsOptions: {
    maxReconnectInterval: 1000,
    reconnectIntervalIncrement: 500,
    maxReconnectAttempts: Infinity,
    silentDeprecation: true,
    heartbeatInterval: 60000,
  },
};
