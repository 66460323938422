import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Page } from '../models/page.model';

@Injectable()
export class AuthService {
    profileSubject = new BehaviorSubject(false);
    notificationCountSubject = new BehaviorSubject(false);

    constructor(private httpClient: HttpClient,
        private router: Router) { }

    currentUser: any;
    path = environment.path + 'signin';
    originPath = environment.path;

    updateUser(userData: any, userId: number) {
        return this.httpClient.put(this.originPath + 'users/' + userId, userData);
    }
   
    verifyUserAccount(body: any, userId: number) {
        return this.httpClient.post(`${this.originPath}users/${userId}/verify-code`, body);
    }

    fetchAllCities() {
        return this.httpClient.get(`${this.originPath}countries/select-cities`);
    }

    login(body: object) {
        return this.httpClient.post(this.path, body);
    }

    setUser(user: any) {
        this.currentUser = user;
        localStorage.setItem('@RES_LOCALSTROAGE', JSON.stringify(this.currentUser));
    }

    setForgetPasswordPhone(body: any) {
        localStorage.setItem('@RES_FORGETPHONE', JSON.stringify(body));
    }

    getForgetPasswordPhone() {
        return JSON.parse(localStorage.getItem('@RES_FORGETPHONE'));
    }

    getUserId() {
        if (this.currentUser.user.type == 'RESTAURANT')
            return this.currentUser.user.id
        else
            return this.currentUser.user.restaurant.id
    }

    getEmployeeId() {
        if (this.currentUser.user.type == 'EMPLOYEE')
            return this.currentUser.user.id
        else return 0
    }

    authUser() {
        const user = localStorage.getItem('@RES_LOCALSTROAGE');
        if (user) {
            this.currentUser = JSON.parse(user);
        }
    }

    isAuthenticated(): boolean {
        return this.currentUser != null;
    }

    payment(orderId: any, paymentBody: any) {
        return this.httpClient.post(`${this.originPath}orders/${orderId}/payments`, paymentBody);
    }

    isAuthenticatedAndCompleteInfo(): boolean {
        if (this.currentUser && this.currentUser.user) {
            if (this.currentUser.user.type != 'RESTAURANT')
                return true
            else
                return this.currentUser.user.accepted;
        }
        return false
    }

    isAuthenticatedAndVerifiedPhone(): boolean {
        if (this.currentUser && this.currentUser.user) {
            if (this.currentUser.user.type != 'RESTAURANT')
                return true
            else
                return this.currentUser.user.verified;
        }
        return false
    }

    getUnseenCount() {
        let unseenCount = this.originPath + 'notifications/notifications-count';
        return this.httpClient.get<any>(unseenCount);
    }

    getUnSeenNotifications() {
        let notifications = this.originPath + 'notifications/unseen-notifications';
        return this.httpClient.get<any>(notifications);
    }


    getNotifications(restaurantId: number, page: number, limit: number, seen: boolean) {
        let notifications = `${this.originPath}restaurants/${restaurantId}/notifications?page=${page}&limit=${limit}`;

        if (seen != undefined) notifications = `${notifications}&seen=${seen}`

        return this.httpClient.get<Page<any>>(notifications);
    }

    getUnSeenNotificationsCount(restaurantId: number) {
        let notifications = `${this.originPath}restaurants/${restaurantId}/notifications-count`;
        return this.httpClient.get<any>(notifications);
    }

    makeNotificationsSeen(notificationId: any, restaurantId: number) {
        return this.httpClient.put(`${this.originPath}restaurants/${restaurantId}/notifications/${notificationId}`, {});
    }

    resetUnSeenNotificationsCount(restaurantId: number) {
        let notifications = `${this.originPath}restaurants/${restaurantId}/notifications-count`;
        return this.httpClient.delete<any>(notifications);
    }

    getToken() {
        return this.currentUser.token;
    }

    logout() {
        this.currentUser = null;
        localStorage.removeItem('@RES_LOCALSTROAGE');
        this.router.navigate(['signin']);
    }

    getLanguage() {
        return localStorage.getItem('@RES_lang');
    }

    setLanguage(lang: string) {
        localStorage.setItem('@RES_lang', lang);
        return;
    }

    //forget password

    forgetPassword(body: any) {
        return this.httpClient.post(`${this.originPath}restaurants/forget-password`, body);
    }

    confirmResetPasswordCode(body: any) {
        return this.httpClient.post(`${this.originPath}restaurants/confirm-reset-password-code`, body);
    }

    resetPassword(body: any) {
        return this.httpClient.post(`${this.originPath}restaurants/reset-password`, body);
    }

}
