import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Page } from '../models/page.model';
import { Observable } from 'rxjs';


@Injectable()
export class EmployeeService {

    originPath = environment.path;
    path = environment.path + 'restaurants';

    constructor(private httpClient: HttpClient) { }

    addEmployee(restaurantId: number, employeBody: any) {
        return this.httpClient.post(`${this.path}/${restaurantId}/employees`, employeBody);
    }

    fetchAllEmployees(page: number, restaurantId: number): Observable<Page<any>> {
        let employePath = `${this.path}/${restaurantId}/employees?page=${page}&limit=12`;
        return this.httpClient
            .get<Page<any>>(employePath);
    }

    findById(employeeId: any, restaurantId: any) {
        return this.httpClient.get(`${this.path}/${restaurantId}/employees/${employeeId}`);
    }

    updatedById(employeeId: number, restaurantId: number, employeBody: any) {
        return this.httpClient.put(`${this.path}/${restaurantId}/employees/${employeeId}`, employeBody);
    }

    addPermissions(employeeId: number, restaurantId: number, employeBody: any) {
        return this.httpClient.put(`${this.path}/${restaurantId}/employees/${employeeId}/permissions`, employeBody);
    }

    blockUnBlock(employeeId: number, restaurantId: number) {
        return this.httpClient.put(`${this.path}/${restaurantId}/employees/${employeeId}/block`, {});
    }

    deleteById(employeeId: number, restaurantId: number) {
        return this.httpClient.delete(`${this.path}/${restaurantId}/employees/${employeeId}`);
    }

    checkPermission(permissin: any, restaurantId: number, employeeId: number) {
        return this.httpClient.get(`${this.path}/${restaurantId}/employees/${employeeId}/check-employees-permission/${permissin}`);
    }
}
