import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { NativeDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/native-date-time-adapter.class';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-filter-date-picker',
  templateUrl: './filter-date-picker.component.html',
  styleUrls: ['./filter-date-picker.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en' },
    {
      provide: DateTimeAdapter,
      useClass: NativeDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE, Platform]
    }
  ]
})
export class FilterDatePickerComponent implements OnInit {
  min = new Date();
  @Output() dateChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onChange(event) {
    this.dateChange.emit(event);
  }
}
