import { Component, OnInit, Inject, Injector } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Deepstream } from '../service/DsService.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// import { io } from 'socket.io-client';
import * as io from 'socket.io-client';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  currentLanguage: string;
  loadingNotifactions: boolean
  unseenCount: number = 0
  restaurantId: number

  record: any
  currentUser: any
  notifications: any
  dsService: any

  NotificationNSP: any;

  constructor(private router: Router,
    private authService: AuthService,
    private inject: Injector,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document) {

    this.getLanguage();
    if (this.currentLanguage == 'ar') {
      this.translate.use('ar');
      this.document.getElementById('rtlBootstrapStyles').setAttribute('href', 'assets/css/style_ar.css');

    }
    else if (this.currentLanguage == 'en') {
      this.translate.use('en');

      this.document.getElementById('layoutStyles').setAttribute('href', 'assets/css/style_en.css');
      this.document.getElementById('rtlBootstrapStyles').removeAttribute('href');
    }
  }

  ngOnInit() {

    this.currentUser = this.authService.currentUser.user;


    if (this.currentUser.type == "EMPLOYEE")
      this.currentUser = this.authService.currentUser.user.restaurant

    this.initNotificationSocket();

    // this.dsService = this.inject.get(Deepstream);

    // this.dsService.anonymousLogin();
    // this.bRecord()

    // this.getUnseenCount()
    this.getUnSeenNotificationsCount()

    this.authService.profileSubject.subscribe(
      (data) => {
        if (data)
          this.currentUser = this.authService.currentUser.user;
      }
    )

  }

  bRecord() {
    this.record = this.dsService.ds.record.getRecord(`branches/4/notifications`)
      .subscribe(notify => {
        console.log('branches/13/notifications ===>', notify);
      });
  }

  getNotifications(page: number) {
    this.loadingNotifactions = true
    this.authService.getNotifications(this.currentUser.id, page, 5, false).subscribe(notifications => {
      this.notifications = notifications.data
      this.loadingNotifactions = false
      console.log('notifications --->', this.notifications);
      if (this.unseenCount > 0) this.resetUnSeenNotificationsCount()
    })
  }

  showNotificationDetails(notificationsId: number, notificationsType: any, order: any) {
    this.authService.makeNotificationsSeen(notificationsId, this.currentUser.id).subscribe(notify => {
      if (['BRANCH_ACTIVATION', 'BRANCH_DEACTIVATION', 'BRANCH_CHANGED_PASSWORD'].includes(notificationsType)) this.router.navigate(['branches', order, 'show'])
      if (['PRODUCT_ACTIVATION', 'PRODUCT_DEACTIVATION'].includes(notificationsType)) this.router.navigate(['products', order, 'show'])
    })
  }

  showNotifications() {
    this.router.navigate(['notifications'])
  }

  getUnSeenNotificationsCount() {
    this.authService.getUnSeenNotificationsCount(this.currentUser.id)
      .subscribe(resetCount => {
        console.log('resetCount--->', resetCount);
        this.unseenCount = resetCount.notificationsCount
      })
  }


  resetUnSeenNotificationsCount() {
    this.authService.resetUnSeenNotificationsCount(this.currentUser.id)
      .subscribe(resetCount => {
        console.log('resetCount- 1111-->', resetCount);
        // this.getUnseenCount();
        this.unseenCount = 0
      })
  }

  getUnseenCount() {
    this.record = this.dsService.ds.record.getRecord(`restaurants/${this.currentUser.id}/notifications`)
      .subscribe(notify => {
        console.log('notify ===>', notify);
        this.unseenCount = notify.unseenCount;
      });
  }

  initNotificationSocket() {
    this.NotificationNSP = io(environment.origin + 'restaurant-notifications');
    this.NotificationNSP.on("connect", () => {

      this.NotificationNSP.emit("join", { 'id': this.currentUser.id });
      this.NotificationNSP.on("notificationCount", data => {
        console.log('data --->', data);

        this.unseenCount = data.notificationCount;
      })
    });
  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
  }

  changeLanguage(language: string) {
    if (language == this.currentLanguage) return

    this.authService.setLanguage(language);
    location.reload();
  }

  logout() {
    this.authService.logout();
  }

  profile() {
    this.router.navigate(['restaurants', this.currentUser.id]);
  }

  ngOnDestroy(): void {
    if (this.record) {
      this.record.unsubscribe();
    }
  }

}
