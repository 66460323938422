import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})

export class MainPageComponent implements OnInit {

  currentUser: any

  constructor(private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.currentUser)
      this.currentUser = this.authService.currentUser.user;
  }

  login() {
    if (this.currentUser)
      this.router.navigate(['/dashbord'])
    else
      this.router.navigate(['/login'])
  }

}
