import { Component } from '@angular/core';
import { AuthService } from './core/service/auth.service';
import * as $ from 'jquery';
import { transition, trigger } from '@angular/animations';
import { slideRight, slideLeft } from './slideInOutAnimation.animation';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('routerAnimations', [
      // transition('*<=*',slideRight ),
      transition('*<=>*', slideLeft),
    ])
  ]
})
export class AppComponent {
  title = 'resturant';
  constructor( private authService: AuthService) {
    this.authService.authUser() ;
  }

  prepareRouteTransition(outlet) {
    const animation = outlet.activatedRouteData['animation'] || {};
    return animation['value'] || null;
  }
}
