import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-terms&conditions',
  templateUrl: './terms&conditions.component.html',
  styleUrls: ['./terms&conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  date = moment().format('YYYY');
  constructor() { }

  ngOnInit() {
  
   
  }

}
