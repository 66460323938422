import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  currentPhone: any;

  loading: Boolean;
  loginError: Boolean;


  constructor(private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.forgetPasswordForm = new FormGroup({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(6), this.validateConfirmPassword.bind(this)])
    });
    this.currentPhone = this.authService.getForgetPasswordPhone();
  }

  validateConfirmPassword(control: FormControl) {

    if (!this.forgetPasswordForm || !this.forgetPasswordForm.get('newPassword').value) {
      return null;
    }

    if (control.value != this.forgetPasswordForm.get('newPassword').value) {
      return { confirm: true };
    }
    return null;
  }

  onSubmit() {
    if (!this.forgetPasswordForm.valid) {
      Object.keys(this.forgetPasswordForm.controls).forEach(key => {
        this.forgetPasswordForm.get(key).markAsTouched();
      });
      return;
    }
    const body = {
      phone: this.currentPhone.phone,
      newPassword: this.forgetPasswordForm.get('newPassword').value,
    };

    this.loading = true;

    this.authService.forgetPassword(body).subscribe(res => {

      console.log('the user is ===>', res);

      this.loading = false;

      this.router.navigate(['signin'])

    },
      err => {
        console.log(err);
        this.loading = false;
        if (err.status == 401) {
          this.loginError = true;
        }
        setTimeout(() => {
          this.loginError = false;
        }, 2000);
      }
    );
  }

  login() {
    this.router.navigate(['signin'])
  }

}
