import { Subject } from 'rxjs';

export class LoadingService {
    loadingSubject = new Subject<boolean>() ;

    show(){
       this.loadingSubject.next(true) ;
    }

    hide(){
       this.loadingSubject.next(false) ;
    }
}