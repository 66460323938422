import { Injectable } from '@angular/core';
import { Router , CanActivate , RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router' ;
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard {
    constructor(private router: Router , private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const url = state.url ;

        if (! this.authService.isAuthenticated()) {
             this.router.navigate(['signin'] , { skipLocationChange : true , queryParams : { url } }) ;
             return false ;
         }

        return true ;
    }
}
