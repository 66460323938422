import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  loading: Boolean;
  loginError: Boolean;
  phonesNotFound:any
  currentLanguage: string;

  constructor(private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.forgetPasswordForm = new FormGroup({
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
    });

  }

  onSubmit() {
    if (!this.forgetPasswordForm.valid) {
      Object.keys(this.forgetPasswordForm.controls).forEach(key => {
        this.forgetPasswordForm.get(key).markAsTouched();
      });
      return;
    }
    const body = {
      phone: this.forgetPasswordForm.get('phone').value,
    };

    this.loading = true;

    this.authService.forgetPassword(body).subscribe(res => {

      console.log('the user is ===>', res);

      this.authService.setForgetPasswordPhone(body);
      this.loading = false;

      this.router.navigate(['confirm-code']);
    },
      err => {
        console.log(err);
        this.loading = false;

        for (let i = 0; i < err.error.errors.length; i++) {
          console.log(err.error.errors);
          if (err.error.errors[i].param == 'phone') {
            this.phonesNotFound = err.error.errors[i].msg
            setTimeout(() => {
              this.phonesNotFound = ''
            }, 4000);
          }
        }

      }
    );
  }

  login(){
    this.router.navigate(['signin'])
  }

}
