import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class CountriesService {

    originPath = environment.path;
    path = environment.path + 'countries';

    constructor(private httpClient: HttpClient) { }

    fetchCountries() {
        return this.httpClient.get(`${this.path}/select-countries`);
    }
  
    fetchAllCites() {
        return this.httpClient.get(`${this.path}/select-cities`);
    }

    fetchCities(countryId: number) {
        return this.httpClient.get(`${this.path}/${countryId}/cities/select-cities`);
    }

    checkNationalNumber(body: any) {
        return this.httpClient.post(`${this.originPath}check-national-number`, body);
    }

}
