import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from '../service/orders.service';
import { AuthService } from '../service/auth.service';
import { Page } from '../models/page.model';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as moment from 'moment';
import { BranchesService } from '../service/branches.service';
// import * as html2canvas from 'html2canvas';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.css']
})

export class DashbordComponent implements OnInit {
  @ViewChild("screen", { static: false }) screen: ElementRef;
  @ViewChild("screen2", { static: false }) screen2: ElementRef;
  @ViewChild("canvasImage", { static: false }) canvasImage: ElementRef;
  @ViewChild("canvasImage2", { static: false }) canvasImage2: ElementRef;
  @ViewChild("downloadLink", { static: true }) downloadLink: ElementRef;
  @ViewChild("downloadLink2", { static: true }) downloadLink2: ElementRef;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  loading: boolean;
  loadCities: boolean;
  loadBranches: Boolean
  loadingStatistic: Boolean
  loadingOrderChartStatistic: Boolean
  loadingOrderChartPromoOffersStatistic: Boolean

  currentLanguage: any;
  statistic: any
  orderChartStatistic: any
  orderChartPromoOffersStatistic: any

  currentPage = new Page();
  activePage: number;

  dropdownSettings = {};
  branchesList = [];
  cityList = [];

  cities: any
  branches: any;

  startDate: any = '';
  endDate: any = '';
  searchBranch = ''
  selectedCity: any = ''

  startDate2: any = '';
  endDate2: any = '';
  searchBranch2 = ''
  selectedCity2: any = ''

  lineChartData: ChartDataSets[];
  lineChartLabels: Label[] = [];

  lineChartPromoOffersData: ChartDataSets[];
  lineChartPromoOffersLabels: Label[] = [];

  lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 18,
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-1',
          position: 'left',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };

  lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(175,252,175,0.7)',
      borderColor: 'rgba(175,252,175,1)',
      pointBackgroundColor: 'rgba(175,252,175,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(175,252,175,1)'
    },
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(255,0,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255,0,0,0.8)'
    },
    { // red
      backgroundColor: 'rgba(68,218,175,0.3)',
      borderColor: 'blue',
      pointBackgroundColor: 'rgba(142,149,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  // public lineChartPlugins = [pluginAnnotations];

  constructor(private ordersService: OrdersService,
    private route: Router,
    private branchesService: BranchesService,
    private authService: AuthService) { }

  ngOnInit() {
    this.fetchOrders(1);
    this.getRestaurantStatistic();
    this.getRestaurantOrderStatistic();
    this.getRestaurantOrderPromoOffersStatistic()
    this.getLanguage();

    this.fetchCities()
    this.getAllBranches();
    this.dropDownSettings();
  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
  }

  fetchOrders(page: number) {
    this.loading = true;

    this.ordersService.fetchAllOrders(page, this.authService.getUserId(), 'CONFIRM_PREPARING', '', '', '').subscribe(result => {
      this.loading = false;
      this.currentPage = result['orders'];
      this.activePage = result['orders'].page;

      console.log('1233000----44', result);

    });
  }

  getPage(page: number) {
    if (this.activePage !== page) {
      this.fetchOrders(page);
    }
  }

  getRestaurantStatistic() {
    this.loadingStatistic = true;
    this.ordersService.getRestaurantStatistic(this.authService.getUserId()).subscribe(result => {
      this.loadingStatistic = false;
      this.statistic = result;

      console.log('getRestaurantStatistic', result);
    });
  }

  getRestaurantOrderStatistic() {
    this.loadingOrderChartStatistic = true;
    this.lineChartLabels = []
    this.ordersService.getRestaurantOrderStatistic(
      this.authService.getUserId(), this.startDate, this.endDate, this.selectedCity, this.searchBranch
    ).subscribe(result => {
      this.orderChartStatistic = result;

      console.log('getRestaurantOrderStatistic', result);

      let acceptedCount = [], cancelledCount = [],
        endedCount = [], rejectedCount = [];

      for (let dayOrderChartStatistic of this.orderChartStatistic.report) {
        this.lineChartLabels.push(moment(new Date(dayOrderChartStatistic.day)).format('D MMM'))

        acceptedCount.push(dayOrderChartStatistic.report.acceptedCount)
        cancelledCount.push(dayOrderChartStatistic.report.cancelledCount)
        rejectedCount.push(dayOrderChartStatistic.report.rejectedCount)
        endedCount.push(dayOrderChartStatistic.report.endedCount)
      }

      this.lineChartData = [
        { data: acceptedCount, label: this.currentLanguage == 'ar' ? 'تمت الموافقة' : 'accepted Orders' },
        { data: rejectedCount, label: this.currentLanguage == 'ar' ? 'طلب مرفوض' : 'rejected Orders' },
        { data: cancelledCount, label: this.currentLanguage == 'ar' ? 'طلب ملغي' : 'cancel Orders' },
        { data: endedCount, label: this.currentLanguage == 'ar' ? 'تم الاستلام' : 'ended Orders' },

      ]

      this.loadingOrderChartStatistic = false;
      console.log(' this.lineChartData  --->', this.lineChartData);

    });
  }

  getRestaurantOrderPromoOffersStatistic() {
    this.loadingOrderChartPromoOffersStatistic = true;
    this.lineChartPromoOffersLabels = []

    this.ordersService.getRestaurantOrderPromoOffersStatistic(
      this.authService.getUserId(), this.startDate, this.endDate, this.selectedCity, this.searchBranch
    ).subscribe(result => {
      this.orderChartPromoOffersStatistic = result;

      console.log('getRestaurantOrderStatistic', result);

      let orders = [], orderHasOffersCount = [],
        orderHasDiscountsCount = [], orderHasPromoCodesCount = [];

      for (let dayOrderChartStatistic of this.orderChartPromoOffersStatistic.report) {
        this.lineChartPromoOffersLabels.push(moment(new Date(dayOrderChartStatistic.day)).format('D MMM'))

        orders.push(dayOrderChartStatistic.report.endedCount)
        orderHasOffersCount.push(dayOrderChartStatistic.report.offersOrdersCount)
        orderHasDiscountsCount.push(dayOrderChartStatistic.report.discountsOrdersCount)
        orderHasPromoCodesCount.push(dayOrderChartStatistic.report.promoCodeOrdersCount)
      }

      this.lineChartPromoOffersData = [
        { data: orders, label: this.currentLanguage == 'ar' ? 'كل الطلبات' : 'all Orders' },
        { data: orderHasOffersCount, label: this.currentLanguage == 'ar' ? 'العروض' : 'Orders Have Offers' },
        { data: orderHasDiscountsCount, label: this.currentLanguage == 'ar' ? 'الخصومات' : 'Orders Have Discount' },
        { data: orderHasPromoCodesCount, label: this.currentLanguage == 'ar' ? 'البرموكود' : 'Orders Have PromoCodes' },

      ]

      this.loadingOrderChartPromoOffersStatistic = false;
    });
  }

  showOrder(orderId: number) {
    this.route.navigate(['orders', orderId])
  }

  getPercentOfOrders(count: number, total: number) {
    let division = count / total;
    return division ? division.toFixed(2) : 0
  }


  dropDownSettings() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: this.currentLanguage == 'ar' ? 'اختيار الكل' : 'Select All',
      unSelectAllText: this.currentLanguage == 'ar' ? 'الغاء الاختيار' : 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getAllBranches() {
    this.loadBranches = true;
    this.branchesService.getSelection(this.authService.getUserId()).subscribe(
      branches => {
        console.log('branches ===>', branches);

        this.branches = branches;
        for (let branche of this.branches) {
          this.branchesList.push({ item_id: branche.id, item_text: branche.streetName })
        }
        this.loadBranches = false;
      }
    );
  }

  onBranchSelect(branch: any) {
    console.log('branch --->', branch);

    this.searchBranch = branch.item_id
    this.getRestaurantOrderStatistic();
  }

  onBranchDeSelect(branch: any) {
    console.log('branch -branch-->', branch);
    this.searchBranch = ''
    this.getRestaurantOrderStatistic();
  }

  onFromDateChange(event: Date) {
    this.startDate = event

    if (this.startDate && this.endDate)
      this.getRestaurantOrderStatistic();
  }

  onToDateChange(event: Date) {
    this.endDate = event

    if (this.startDate && this.endDate)
      this.getRestaurantOrderStatistic();
  }

  onCitySelect(item: any) {
    this.selectedCity = item.item_id;
    this.getRestaurantOrderStatistic();
  }

  onCityDeSelect(item: any) {
    this.selectedCity = '';
    this.getRestaurantOrderStatistic();
  }

  fetchCities() {
    this.loadCities = true;
    this.authService.fetchAllCities().subscribe(
      cities => {
        this.cities = cities

        this.loadCities = false;
        for (let city of this.cities) {
          this.cityList.push({ item_id: city.id, item_text: city.name })
        }

      }
    );
  }

  onBranchSelect2(branch: any) {
    console.log('branch --->', branch);

    this.searchBranch2 = branch.item_id
    this.getRestaurantOrderPromoOffersStatistic();
  }

  onBranchDeSelect2(branch: any) {
    console.log('branch -branch-->', branch);
    this.searchBranch2 = ''
    this.getRestaurantOrderPromoOffersStatistic();
  }

  onFromDateChange2(event: Date) {
    this.startDate2 = event

    if (this.startDate2 && this.endDate2)
      this.getRestaurantOrderPromoOffersStatistic();
  }

  onToDateChange2(event: Date) {
    this.endDate2 = event

    if (this.startDate2 && this.endDate2)
      this.getRestaurantOrderPromoOffersStatistic();
  }

  onCitySelect2(item: any) {
    this.selectedCity2 = item.item_id;
    this.getRestaurantOrderPromoOffersStatistic();
  }

  onCityDeSelect2(item: any) {
    this.selectedCity2 = '';
    this.getRestaurantOrderPromoOffersStatistic();
  }

  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  downloadImage() {
    html2canvas(this.screen.nativeElement, { width: window.innerWidth, height: 1000 }).then(canvas => {
      this.canvasImage.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL("image/png");
      this.downloadLink.nativeElement.download = "chart.png";
      this.downloadLink.nativeElement.click();
    });
  }

  downloadImage2() {
    console.log('this.screen2.nativeElement--->', this.screen2.nativeElement);

    html2canvas(this.screen2.nativeElement, { width: window.innerWidth, height: 1000, scrollY: -1000 }).then(canvas2 => {
      this.canvasImage2.nativeElement.src = canvas2.toDataURL();
      this.downloadLink2.nativeElement.href = canvas2.toDataURL("image/png");
      this.downloadLink2.nativeElement.download = "chart-orders.png";
      this.downloadLink2.nativeElement.click();
    });
  }

}
