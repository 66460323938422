import { RouterModule, PreloadAllModules, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { DashbordComponent } from './dashbord/dashbord.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './service/authGuard.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { NotAllowedPageComponent } from './not-allowed/not-allowed.component';
import { FirstPageRegisterComponent } from './first-page-register/first-page-register.component';
import { SecondPageRegisterComponent } from './second-page-register/second-page-register.component';
import { AddResturantInfoComponent } from './add-resturant-info/add-resturant-info.component';
import { WattingAcceptanceComponent } from './watting-acceptance/watting-acceptance.component';
import { CompleteInfoGuard } from './service/completeInfoGuard.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ConfirmForgetPasswordComponent } from './confirm-forget-password/confirm-forget-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MainPageComponent } from './main-page/main-page.component';
import { TermsConditionsComponent } from './terms&conditions/terms&conditions.component';
import { PrivacyPoliticyComponent } from './privacy-politicy/privacy-politicy.component';
import { PermissionResolver } from './resolves/permission.resolver';
import { OrderPaymentComponent } from './order-payment/order-payment.component';
import { OrderPaymentResolver } from './resolves/order-payment.resolver';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';

const appRouting: Routes = [
    { path: '', component: MainPageComponent },
    { path: 'signin', component: LoginComponent },
    { path: 'register', component: FirstPageRegisterComponent },
    { path: 'verify-account', component: ConfirmAccountComponent },
    // { path: 'orders-payment/:orderId/language/:lang', component: OrderPaymentComponent, resolve: { type: OrderPaymentResolver } },

    // { path: 'privacy-politicy', component: PrivacyPoliticyComponent },
    { path: 'terms&conditions', component: TermsConditionsComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },
    { path: 'confirm-code', component: ConfirmForgetPasswordComponent },
    { path: 'add-new-password', component: NewPasswordComponent },
    { path: 'complete-register', component: SecondPageRegisterComponent, canActivate: [AuthGuard] },
    { path: 'add-resturant-details', component: AddResturantInfoComponent, canActivate: [AuthGuard] },
    { path: 'watting-acceptance', component: WattingAcceptanceComponent, canActivate: [AuthGuard] },
    {
        path: '', component: HomeComponent, canActivate: [CompleteInfoGuard], children: [
            { path: 'dashbord', component: DashbordComponent, resolve: { permission: PermissionResolver }, data: ['HOME'] },
            { path: 'notifications', component: NotificationsComponent },
            { path: 'categories', loadChildren: '../features/categories/categories.module#CategoriesModule' },
            { path: 'promo-code', loadChildren: '../features/promo-code/promo-code.module#PromoCodeModule' },
            { path: 'branches', loadChildren: '../features/branches/branches.module#BranchesModule' },
            { path: 'offers', loadChildren: '../features/offers/offers.module#OffersModule' },
            { path: 'products', loadChildren: '../features/products/products.module#ProductsModule' },
            { path: 'users', loadChildren: '../features/employees/employees.module#EmployeeModule' },
            { path: 'extra', loadChildren: '../features/extra/extra.module#ExtraModule' },
            { path: 'settings', loadChildren: '../features/settings/settings.module#SettingsModule' },
            { path: 'meals', loadChildren: '../features/meals/meals.module#MealsModule' },
            { path: 'restaurants', loadChildren: '../features/profile/profile.module#ProfileModule' },
            { path: 'orders', loadChildren: '../features/orders/orders.module#OrdersModule' },
            { path: 'clients', loadChildren: '../features/clients/clients.module#ClientModule' },
            { path: 'reports', loadChildren: '../features/report/report.module#ReportModule' },
            { path: 'statistics', loadChildren: '../features/statistics/statistics.module#StatisticsModule' },
            { path: 'management', loadChildren: '../features/contact/contact.module#ContactModule' },

            { path: 'no-permissions', component: NotAllowedPageComponent },
            { path: '404', component: NotFoundPageComponent },
            { path: '**', component: NotFoundPageComponent },
        ]
    }
];
@NgModule({
    imports: [RouterModule.forRoot(appRouting, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
    providers: [AuthGuard, CompleteInfoGuard, PermissionResolver, OrderPaymentResolver]
})
export class AppRoutingModule {

}
