import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Page } from '../models/page.model';
import { Observable } from 'rxjs';
import { BORDER_STYLE } from 'html2canvas/dist/types/css/property-descriptors/border-style';


@Injectable()
export class OrdersService {

    originPath = environment.path;
    path = environment.path + 'restaurants';

    constructor(private httpClient: HttpClient) { }

    fetchAllOrders(
        page: number, restaurantId: number, status: string,
        branch: string, startDate: string, endDate: string,
        product: string = null, offer: string = null, orderId: string = null,
        promoCode: string = null, discount: string = null
    ): Observable<Page<any>> {
        let ordersPath = `${this.path}/${restaurantId}/orders?page=${page}&limit=20`;

        if (status) ordersPath = `${ordersPath}&status=${status}`;
        if (branch) ordersPath = `${ordersPath}&branch=${branch}`;
        if (startDate) ordersPath = `${ordersPath}&startDate=${startDate}`;
        if (endDate) ordersPath = `${ordersPath}&endDate=${endDate}`;

        if (product) ordersPath = `${ordersPath}&product=${product}`;
        if (offer) ordersPath = `${ordersPath}&offer=${offer}`;
        if (orderId) ordersPath = `${ordersPath}&orderId=${orderId}`;
        if (promoCode) ordersPath = `${ordersPath}&promoCode=${promoCode}`;
        if (discount) ordersPath = `${ordersPath}&discount=${discount}`;

        return this.httpClient.get<Page<any>>(ordersPath);
    }

    getRestaurantStatistic(restaurantId: any) {
        return this.httpClient.get(`${this.path}/${restaurantId}/orders-statistic`);
    }

    getRestaurantOrderStatistic(restaurantId: any, startDate = null, endDate = null, city = null, branch = null) {
        let path = `${this.path}/${restaurantId}/order-chart-statistics`

        if (startDate || endDate || city || branch) path = `${path}?admin=admin`
        if (startDate) path = `${path}&startDate=${startDate}`
        if (endDate) path = `${path}&endDate=${endDate}`
        if (city) path = `${path}&city=${city}`
        if (branch) path = `${path}&branch=${branch}`

        return this.httpClient.get(path);
    }

    getRestaurantOrderPromoOffersStatistic(restaurantId: any, startDate = null, endDate = null, city = null, branch = null) {
        let path = `${this.path}/${restaurantId}/orders-promocodes-offers-statistics`

        if (startDate || endDate || city || branch) path = `${path}?admin=admin`
        if (startDate) path = `${path}&startDate=${startDate}`
        if (endDate) path = `${path}&endDate=${endDate}`
        if (city) path = `${path}&city=${city}`
        if (branch) path = `${path}&branch=${branch}`

        return this.httpClient.get(path);
    }

    findById(orderId: any, restaurantId: any) {
        return this.httpClient.get(`${this.path}/${restaurantId}/orders/${orderId}`);
    }

    findOrderById(orderId: any) {
        return this.httpClient.get(`${this.originPath}/orders/${orderId}`);
    }

    categoriesSelect(restaurantId: any) {
        return this.httpClient.get(`${this.path}/${restaurantId}/categories/select-categories`);
    }

    sizesSelect() {
        return this.httpClient.get(`${this.originPath}meal-size/select-meal-sizes`);
    }

    tastesSelect() {
        return this.httpClient.get(`${this.originPath}meal-taste/select-meal-tastes`);
    }

    updatedById(categoryId: number, restaurantId: number, categoryBody: any) {
        return this.httpClient.put(`${this.path}/${restaurantId}/categories/${categoryId}`, categoryBody);
    }

    deleteById(categoryId: number, restaurantId: number) {
        return this.httpClient.delete(`${this.path}/${restaurantId}/categories/${categoryId}`);
    }
}
