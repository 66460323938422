import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import * as moment from 'moment'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentLanguage: string;
  date = moment().format('YYYY');

  constructor(private authService: AuthService) {
    this.getLanguage();
  }

  ngOnInit() {
  }

  getLanguage() {
    this.currentLanguage = this.authService.getLanguage();
  }

}
