import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-forget-password',
  templateUrl: './confirm-forget-password.component.html',
  styleUrls: ['./confirm-forget-password.component.css']
})
export class ConfirmForgetPasswordComponent implements OnInit {
  confirmForgetPasswordForm: FormGroup;
  loading: Boolean;

  verifycodeNotCorrect: any;
  currentPhone: any;

  constructor(private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.confirmForgetPasswordForm = new FormGroup({
      confirmationCode: new FormControl(null, [Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      Validators.pattern(/^[0-9]+$/)]),
    });

    this.currentPhone = this.authService.getForgetPasswordPhone();
    
  }

  onSubmit() {
    if (!this.confirmForgetPasswordForm.valid) {
      Object.keys(this.confirmForgetPasswordForm.controls).forEach(key => {
        this.confirmForgetPasswordForm.get(key).markAsTouched();
      });
      return;
    }

    const body = {
      phone: this.currentPhone.phone,
      confirmationCode: this.confirmForgetPasswordForm.get('confirmationCode').value
    };

    console.log('body --->', body);

    this.loading = true;

    this.authService.confirmResetPasswordCode(body).subscribe(res => {

      this.loading = false;

      this.router.navigate(['add-new-password']);
    },
      err => {
        console.log(err);
        this.loading = false;

        for (let i = 0; i < err.error.errors.length; i++) {
          console.log(err.error.errors);
          if (err.error.errors[i].param == 'verifycode') {
            this.verifycodeNotCorrect = err.error.errors[i].msg
            setTimeout(() => {
              this.verifycodeNotCorrect = ''
            }, 4000);
          }
        }
      }
    );
  }

  login() {
    this.router.navigate(['/','signin'])
  }

}
