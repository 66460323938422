import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Page } from '../models/page.model';
import { Observable } from 'rxjs';


@Injectable()
export class BranchesService {

    originPath = environment.path;
    path = environment.path + 'restaurants';

    constructor(private httpClient: HttpClient) { }

    addBranch(restaurantId: number, branchBody: any) {
        return this.httpClient.post(`${this.path}/${restaurantId}/branches`, branchBody);
    }

    fetchAllBranch(page: number, restaurantId: number): Observable<Page<any>> {
        let categoryPath = `${this.path}/${restaurantId}/branches?page=${page}&limit=12&admin=ADMIN`;
        return this.httpClient
            .get<Page<any>>(categoryPath);
    }

    getSelection(restaurantId: any, identificationCode: any = null, country: any = null, city: any = null) {
        let branchesQuery = `${this.path}/${restaurantId}/branches/select-branches?admin="ADMIN"`

        if (identificationCode) branchesQuery = `${branchesQuery}&identificationCode=${identificationCode}`
        if (country) branchesQuery = `${branchesQuery}&country=${country}`
        if (city) branchesQuery = `${branchesQuery}&city=${city}`

        return this.httpClient.get(branchesQuery);
    }

    findById(branchId: any, restaurantId: any) {
        return this.httpClient.get(`${this.path}/${restaurantId}/branches/${branchId}?admin="ADMIN"`);
    }

    updatedById(branchId: number, restaurantId: number, branchBody: any) {
        return this.httpClient.put(`${this.path}/${restaurantId}/branches/${branchId}`, branchBody);
    }

    deleteById(branchId: number, restaurantId: number) {
        return this.httpClient.delete(`${this.path}/${restaurantId}/branches/${branchId}`);
    }
}
