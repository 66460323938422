import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class RestaurantsService {

    originPath = environment.path;
    path = environment.path + 'restaurants';

    constructor(private httpClient: HttpClient) { }

    addResturantFirstPage(body: any) {
        return this.httpClient.post(`${this.path}`, body);
    }

    resturantCompletePage(body: any, restaurantId: number) {
        return this.httpClient.put(`${this.path}/${restaurantId}/complete-registration`, body);
    }

    resturantInfoPage(body: any, restaurantId: number) {
        return this.httpClient.put(`${this.path}/${restaurantId}/add-restaurant-info`, body);
    }
 
    rateResturant(body: any, restaurantId: number) {
        return this.httpClient.post(`${this.path}/${restaurantId}/rate`, body);
    }

    getRestaurantResponsible(restaurantId: number) {
        return this.httpClient.get(`${this.path}/${restaurantId}/responsible`);
    }

    findById(restaurantId: number) {
        return this.httpClient.get(`${this.path}/${restaurantId}`);
    }

    updateRestaurantById(restaurantId: number, restaurantBody: any) {
        return this.httpClient.put(`${this.path}/${restaurantId}`, restaurantBody);
    }
   
    changeRestaurantPassword(restaurantId: number, restaurantBody: any) {
        return this.httpClient.post(`${this.path}/${restaurantId}`, restaurantBody);
    }

    fetchCategory() {
        return this.httpClient.get(`${this.originPath}/categories/select-categories`);
    }

    fetchBanks() {
        return this.httpClient.get(`${this.originPath}/banks/select-banks`);
    }

    addCategory(categoryBody: any) {
        return this.httpClient.post(`${this.originPath}/categories`, categoryBody);
    }
}
