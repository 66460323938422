import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantsService } from '../service/resturant.service';

@Component({
  selector: 'app-add-resturant-info',
  templateUrl: './add-resturant-info.component.html',
  styleUrls: ['./add-resturant-info.component.css']
})
export class AddResturantInfoComponent implements OnInit {
  addResturantInfo: FormGroup;

  loadRestaurant: Boolean;

  constructor(private authService: AuthService,
    private restaurantsService: RestaurantsService,
    private activeRoute: ActivatedRoute,
    private route: Router
  ) {

  }

  ngOnInit() {
    this.addResturantInfo = new FormGroup({
      parking: new FormControl(null, [Validators.required]),
      tableType: new FormControl(null, [Validators.required]),
      restaurantType: new FormControl(null, [Validators.required]),
      specialEventService: new FormControl(null, [Validators.required]),
      restaurantRating: new FormControl(null, [Validators.required]),
      numberOfTables: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      restaurantCapacity: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)])
    });
    console.log(111111, this.authService.currentUser);

  }


  onSubmit() {
    if (!this.addResturantInfo.valid) {
      Object.keys(this.addResturantInfo.controls).forEach(key => {
        this.addResturantInfo.get(key).markAsTouched();
      });
      return;
    }

    let resturantBody = {
      'parking': this.addResturantInfo.get('parking').value,
      'tableType': this.addResturantInfo.get('tableType').value,
      'restaurantType': this.addResturantInfo.get('restaurantType').value,
      'specialEventService': this.addResturantInfo.get('specialEventService').value,
      'restaurantRating': this.addResturantInfo.get('restaurantRating').value,
      'numberOfTables': this.addResturantInfo.get('numberOfTables').value,
      'restaurantCapacity': this.addResturantInfo.get('restaurantCapacity').value
    };



    this.loadRestaurant = true;

    this.restaurantsService.resturantInfoPage(resturantBody, this.authService.getUserId()).subscribe(
      resturant => {
        this.loadRestaurant = false;
        console.log('register ====>', resturant);

        this.route.navigate(['watting-acceptance'])

      },
      error => {
        console.log('the error is ====>', error.error.error);
        this.loadRestaurant = false;
      }
    );

  }

}
