import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FirstPageRegisterComponent } from './first-page-register/first-page-register.component';
import { SecondPageRegisterComponent } from './second-page-register/second-page-register.component';
import { WattingAcceptanceComponent } from './watting-acceptance/watting-acceptance.component';
import { AddResturantInfoComponent } from './add-resturant-info/add-resturant-info.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotAllowedPageComponent } from './not-allowed/not-allowed.component';
import { LoadingInterceptor } from './service/loading.interceptor.service';
import { LanguageInterceptor } from './service/lang.interceptor.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthInterceptor } from './service/auth.interceptor.service';
import { DashbordComponent } from './dashbord/dashbord.component';
import { RestaurantsService } from './service/resturant.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CountriesService } from './service/countries.service';
import { HeaderComponent } from './header/header.component';
import { EmployeeService } from './service/empoyees.service';
import { LoadingService } from './service/loading.service';
import { BrowserModule } from '@angular/platform-browser';
import { OrdersService } from './service/orders.service';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app.routing.module';
import { SharedModule } from '../shared/shared.module';
import {ProgressBarModule} from "angular-progress-bar"
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AuthService } from './service/auth.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreComponent } from './core.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmForgetPasswordComponent } from './confirm-forget-password/confirm-forget-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MainPageComponent } from './main-page/main-page.component';
import { TermsConditionsComponent } from './terms&conditions/terms&conditions.component';
import { PrivacyPoliticyComponent } from './privacy-politicy/privacy-politicy.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FilterDatePickerComponent } from './filter-date-picker/filter-date-picker.component';
import { BranchesService } from './service/branches.service';
import { OrderPaymentComponent } from './order-payment/order-payment.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { NgOtpInputModule } from 'ng-otp-input';


export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        HeaderComponent,
        CoreComponent,
        MenuComponent,
        HomeComponent,
        DashbordComponent,
        LoginComponent,
        WattingAcceptanceComponent,
        AddResturantInfoComponent,
        FirstPageRegisterComponent,
        SecondPageRegisterComponent,
        NotFoundPageComponent,
        NotAllowedPageComponent,
        ForgetPasswordComponent,
        ConfirmForgetPasswordComponent,
        NewPasswordComponent,
        MainPageComponent,
        FilterDatePickerComponent,
        TermsConditionsComponent,
        ConfirmAccountComponent,
        // PrivacyPoliticyComponent,
        OrderPaymentComponent,
        
        NotificationsComponent
    ],
    providers: [
        AuthService,
        LoadingService,
        RestaurantsService,
        OrdersService,
        EmployeeService,
        BranchesService,
        ThemeService,
        CountriesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,

        },
        {
			provide: HTTP_INTERCEPTORS,
			useClass: LanguageInterceptor,
			multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
          },
    ],
    imports: [
        BrowserModule,
        ProgressBarModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        ChartsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        NgOtpInputModule
    ],
    exports: [
        AppRoutingModule,
    ]
})
export class coreModule { }
